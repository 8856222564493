<template>
Kerj ajanlatot

<Input
  label="Név"
  placeholder="Név"
  :data="name"
/>

<Input
  label="Email cim"
  placeholder="Email"
  validation="email"
  :data="email"
/>

</template>
<script>
import Input from '@/components/form/Input.vue';

export default {
  components: {
    Input,
  },
  data() {
    return {
      name: '',
      email: 'almafa',
    };
  },
};

</script>
