import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "field" }
const _hoisted_2 = { class: "label" }
const _hoisted_3 = { class: "control" }
const _hoisted_4 = ["value", "placeholder"]
const _hoisted_5 = { class: "icon is-small is-left" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_font_awesome_icon, { icon: ['far', 'user-secret'] }),
    _createVNode(_component_font_awesome_icon, { icon: "user-secret" }),
    _createVNode(_component_font_awesome_icon, { icon: "spinner" }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.label), 1),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("input", {
          class: "input has-icons-left",
          type: "text",
          value: _ctx.inputData,
          placeholder: _ctx.placeholder
        }, null, 8, _hoisted_4),
        _createElementVNode("span", _hoisted_5, [
          _createVNode(_component_font_awesome_icon, { icon: "fa-user" })
        ])
      ])
    ])
  ], 64))
}