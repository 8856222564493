
import { defineComponent } from 'vue';

/* eslint-disable no-useless-escape */
const validateEmail = (email: string) => email.match(
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
);

export default defineComponent({
  name: 'Input',
  props: {
    label: String,
    placeholder: String,
    validation: String,
    inputData: String,
  },
  mounted() {
    console.log(this.placeholder);
  },
  methods: {
    validateInput() {
      if (this.validation) {
        // validateEmail(this.inputData);
      }
    },
  },
});
